@import "../less/Variables";
@import "../less/Fonts";

/* Toggle styling */

.toggle {
  .noSelect();
  display: flex;
  min-height: 25px;
  line-height: 25px;
  .defaultFontRegular(10px, @darkSkyBlue);
  outline: none;
  border: none;
  background-color: white;
  text-align: center;

  .toggle-value1 {
    border: solid 1px @darkSkyBlue;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    padding-left: 15px;
    padding-right: 10px;
    text-transform: uppercase;
  }

  .toggle-value2 {
    border: solid 1px @darkSkyBlue;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    padding-left: 10px;
    padding-right: 15px;
    text-transform: uppercase;
  }

  .selected {
    color: white;
    background-color: @darkSkyBlue;
  }

  .unselected {
    &:hover {
      background-color: lighten(@darkSkyBlue, 30%);
    }
  }

  &:hover {
    cursor: pointer;
  }
}
