// Mixins

.clearFix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

.noSelect() {
  user-select: none;
  cursor: default;
}

.report-export-spinner() {
  .export-spinner {
    padding-top: 10px;
    .font36MediumDarkSkyBlue();
    color: darken(@darkSkyBlue, 15%);
    text-align: center;
  }
}

.styled-scrollbar() {
  &::-webkit-scrollbar {
    width: 6px;
    background-color: @paleGrey;
  }

  &::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: @paleGrey;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #BCBCBC;
  }
}
