@import "../../../../less/Variables";
@import "../../../../less/Fonts";

/* TimesheetEntry styling */

.timesheet-entry {
  flex: 1 0 auto;
  padding: 6vw 4vw;
  display: flex;
  flex-direction: column;

  .timesheet-entry-code {
    .font18MediumSteel();
    font-size: 4vw;
    margin-bottom: 5.5vw;
    word-wrap: break-word;
  }

  .timesheet-entry-options.form-group {
    .options {
      border: solid 1px @coolGrey;
      max-height: 54vw;
      overflow: scroll;
      position: relative;

      &.expanded {
        box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.1);
      }

      .option {
        .font14RegularGunmetal();
        font-size: 4vw;
        padding: 3vw 9vw 3vw 3vw;
        word-wrap: break-word;

        &[id='select-a-timesheet-code'] {
          color: @coolGrey;
        }
      }

      .fa-caret-down {
        color: @coolGrey;
        font-size: 4vw;
        position: absolute;
        top: 3.5vw;
        right: 4vw;
      }
    }
  }

  .timesheet-entry-inputs {
    .hours {
      margin-bottom: 2.5vw;

      .form-control {
        display: inline-block;
        width: 20vw;
        text-align: center;
      }

      label {
        display: inline;
        margin-left: 4vw;
        color: @gunmetal;
      }

      .additional-hours {
        .font18MediumSteel();
        font-size: 4vw;
        color: @darkSkyBlue;
        text-transform: uppercase;
      }
    }
  }

  .timesheet-entry-actions {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    input[type="submit"], input[type="button"] {
      float: initial;
      margin: 0;
      width: 75vw;
      height: 12.7vw;
      font-size: 4vw;

      &[disabled] {
        background-color: @darkSkyBlue50;
      }
    }

    .button-spacing {
      margin-bottom: 3vw;
    }
  }
}
