@import "Variables";

// FONT DEFAULT
.defaultFont(@size, @color, @weight) {
  font-family: Rubik, sans-serif;
  font-weight: @weight;
  font-size: @size;
  color: @color;
}

.defaultFontLight(@size, @color) {
  .defaultFont(@size, @color, 300);
}

.defaultFontRegular(@size, @color) {
  .defaultFont(@size, @color, 400);
}

.defaultFontMedium(@size, @color) {
  .defaultFont(@size, @color, 500);
}

.defaultFontBold(@size, @color) {
  .defaultFont(@size, @color, 900);
}

// FONT 8
.font8MediumSteel() {
  .defaultFontMedium(8px, @steel)
}

// FONT 10
.font10LightSteel() {
  .defaultFontLight(10px, @steel);
}

.font10LightGray() {
  .defaultFontLight(10px, @grey);
}

.font10RegularSteel() {
  .defaultFontRegular(10px, @steel);
}

.font10MediumSteel() {
  .defaultFontMedium(10px, @steel)
}

// FONT 12
.font12LightSteel() {
  .defaultFontLight(12px, @steel);
}

.font12LightDarkSkyBlue() {
  .defaultFontLight(12px, @darkSkyBlue);
}

.font12LightBlack() {
  .defaultFontLight(12px, black);
}

.font12LightGrey() {
  .defaultFontLight(12px, @grey);
}

.font12RegularSteel() {
  .defaultFontRegular(12px, @steel);
}

.font12RegularWhite() {
  .defaultFontRegular(12px, @white);
}

.font12RegularBlack() {
  .defaultFontRegular(12px, black);
}

.font12RegularGunmetal() {
  .defaultFontRegular(12px, @gunmetal);
}

.font12RegularDarkSkyBlue() {
  .defaultFontRegular(12px, @darkSkyBlue);
}

.font12RegularCoolGrey() {
  .defaultFontRegular(12px, @coolGrey);
}

.font12RegularGray() {
  .defaultFontRegular(12px, @grey);
}

.font12MediumLightBlue() {
  .defaultFontMedium(12px, @lightBlue);
}

.font12MediumWhite() {
  .defaultFontMedium(12px, @white);
}

.font12MediumSteel() {
  .defaultFontMedium(12px, @steel)
}

.font12MediumBlack() {
  .defaultFontMedium(12px, black)
}

.font12MediumPaleGreyTwo() {
  .defaultFontMedium(12px, @paleGreyTwo)
}

.font12MediumGunMetal() {
  .defaultFontMedium(12px, @gunmetal);
}

.font12MediumCoolGrey() {
  .defaultFontMedium(12px, @coolGrey);
}

.font12MediumDarkSkyBlue {
  .defaultFontMedium(12px, @darkSkyBlue);
}

// FONT 13
.font13RegularWhite() {
  .defaultFontRegular(13px, @white);
}

// FONT 14
.font14RegularSteel() {
  .defaultFontRegular(14px, @steel);
}

.font14RegularGray() {
  .defaultFontRegular(14px, @grey);
}

.font14RegularWhite() {
  .defaultFontRegular(14px, @white);
}

.font14RegularGunmetal() {
  .defaultFontRegular(14px, @gunmetal);
}

.font14RegularWarmPink() {
  .defaultFontRegular(14px, @warmPink);
}

.font14RegularDarkSkyBlue() {
  .defaultFontRegular(14px, @darkSkyBlue);
}

.font14RegularBlack() {
  .defaultFontRegular(14px, black);
}

.font14MediumSteel() {
  .defaultFontMedium(14px, @steel);
}

.font14MediumWhite() {
  .defaultFontMedium(14px, @white);
}

.font14MediumDarkSkyBlue {
  .defaultFontMedium(14px, @darkSkyBlue);
}

.font14MediumWarmPink {
  .defaultFontMedium(14px, @warmPink);
}

.font14MediumGunMetal() {
  .defaultFontMedium(14px, @gunmetal);
}

.font14MediumBlack() {
  .defaultFontMedium(14px, black);
}

// FONT 15
.font15RegularGunmetal() {
  .defaultFontRegular(15px, @gunmetal);
}

.font15MediumGunmetal() {
  .defaultFontMedium(15px, @gunmetal);
}

// FONT 16
.font16LightDarkSkyBlue() {
  .defaultFontLight(16px, @darkSkyBlue)
}

.font16LightWhite() {
  .defaultFontLight(16px, @white)
}

.font16RegularSteel() {
  .defaultFontRegular(16px, @steel);
}

.font16RegularBattleShipGrey() {
  .defaultFontRegular(16px, @battleShipGrey);
}

.font16RegularCoolGrey() {
  .defaultFontRegular(16px, @coolGrey);
}

.font16RegularGray() {
  .defaultFontRegular(16px, @grey);
}

.font16RegularBlack() {
  .defaultFontRegular(16px, black);
}

.font16MediumWarmPink {
  .defaultFontMedium(16px, @warmPink);
}

.font16MediumSteel() {
  .defaultFontMedium(16px, @steel);
}

.font16MediumDarkGray() {
  .defaultFontMedium(16px, @darkGrey);
}

.font16MediumCoolGrey() {
  .defaultFontMedium(16px, @coolGrey);
}

.font16MediumDarkSkyBlue() {
  .defaultFontMedium(16px, @darkSkyBlue);
}

// FONT 18
.font18RegularSteel() {
  .defaultFontRegular(18px; @steel);
}

.font18RegularGray() {
  .defaultFontRegular(18px, @grey);
}

.font18MediumSteel() {
  .defaultFontMedium(18px; @steel);
}

.font18MediumBlack() {
  .defaultFontMedium(18px; black);
}

.font18MediumGray() {
  .defaultFontMedium(18px, @grey);
}

// FONT 19
.font19MediumWhite() {
  .defaultFontMedium(19px; @white);
}

.font19BoldWhite() {
  .defaultFontBold(19px; @white);
}

.font19LightSteel() {
  .defaultFontLight(19px, @steel);
}

.font19MediumSteel() {
  .defaultFontMedium(19px; @steel);
}

// FONT 20
.font20RegularGunmetal() {
  .defaultFontRegular(20px; @gunmetal);
}

.font20MediumGunmetal() {
  .defaultFontMedium(20px; @gunmetal);
}

.font20RegularSteel() {
  .defaultFontRegular(20px; @steel);
}

.font20MediumSteel() {
  .defaultFontMedium(20px; @steel);
}

.font20MediumBlack() {
  .defaultFontMedium(20px; black);
}

.font20LightWhite() {
  .defaultFontLight(20px, @white);
}

.font20MediumDarkSkyBlue() {
  .defaultFontMedium(20px, @darkSkyBlue);
}

.font20BoldWhite() {
  .defaultFontBold(20px, @white);
}

// FONT 24
.font24RegularSteel() {
  .defaultFontRegular(24px, @steel);
}

.font24MediumSteel() {
  .defaultFontMedium(24px, @steel);
}

.font24MediumBlack() {
  .defaultFontMedium(24px, black);
}

// FONT 36
.font36MediumBlack() {
  .defaultFontMedium(36px, black);
}

.font36MediumDarkSkyBlue() {
  .defaultFontMedium(36px,@darkSkyBlue);
}

.font36MediumSteel() {
  .defaultFontMedium(36px, @steel);
}

// FONT 46
.font46MediumWhite() {
  .defaultFontMedium(46px, @white);
}

// FONT 128
.font128MediumBlack() {
  .defaultFontMedium(128px, black);
}