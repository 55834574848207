@import "../../../../less/Fonts";

/* TimesheetFooterDay styling */

.timesheet-footer {
  .day {
    .font18RegularSteel();
    font-size: 4.25vw;
    margin-top: 6.2vw;
    margin-bottom: 2.5vw;
    text-align: center;
  }
}
