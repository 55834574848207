@import "../../../../less/Variables";
@import "../../../../less/Fonts";

/* TimesheetNavigation styling */

.timesheet-navigation {
  flex: 0 0 @headerHeightMobile;
  box-shadow: 0 1px 0 0 @silver;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .navigate-left, .navigate-right {
    position: absolute;
    bottom: 0.5vw;
    line-height: ~"calc(@{headerHeightMobile} - 1vw)";
    width: 11.2vw;
    text-align: center;
    font-size: 9vw;
    color: @steel;

    &:active {
      color: @white;
      background-color: @darkSkyBlue;
    }
  }

  .navigate-left {
    left: 0;
  }

  .navigate-right {
    right: 0;
  }

  .timesheet-navigation-month {
    .totalHours-container {
      width: 100%;
      height: @headerHeightMobile / 2;
      line-height: @headerHeightMobile / 2;
      display: flex;
      margin: 0 auto;
      justify-content: center;

      .totalHours {
        .font16MediumCoolGrey();
        font-size: 3.8vw;
      }

      .totalHoursText {
        margin-left: 2vw;
        margin-top: 0.3vw;
        .font12MediumCoolGrey();
        font-size: 2.7vw;
        text-transform: uppercase;
      }
    }
  }
}

@import "SwitchMonth";