
@import "../../../../less/Variables";
@import "../../../../less/Fonts";


//TimesheetCompleteDetail Style

.timesheet-complete-detail {
    .timesheet-return {
        .message {
            font-size: 3.6vw;
        }
    }

     .timesheet-complete {
        flex: 1 0 auto;
        padding: 6vw 4vw;
        display: flex;
        flex-direction: column;
        align-items: initial;

        .timesheet-complete-actions {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            margin-top: 3vw;

            input[type="submit"], input[type="button"] {
                float: initial;
                margin: 0;
                width: 75vw;
                height: 12.7vw;
                font-size: 4vw;
            }

            .disabled {
                background-color: @darkSkyBlue50;
            }

            .button-spacing {
                margin-bottom: 3vw;
            }
        }

        .timesheet-complete-list {
            .scrollbar-container-timesheet-complete-list {
                height: 55vh;
            }

            .timesheet-code {
                display: inline-flex;
                width: 97%;
                box-sizing: content-box;
                padding: 2.5vw 0 2.5vw 2vw;

                .timesheet-code-name {
                    .font14RegularGunmetal();
                    font-size:3.4vw;
                    line-height: 5.5vw;
                    padding-left: 2vw;
                    word-wrap: break-word;
                }
            }
        }
     }
}
