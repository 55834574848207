@import "../../../../less/Variables";
@import "../../../../less/Fonts";
@import "../../../../less/Mixins";

/* Settings styling */

.settings-detail {
    .noSelect();
    height: @timesheetHeightMobile;
    display: flex;
    flex-direction: column;

    .settings-item {
        display: flex;
        align-items: center;
        padding: 15px 5%;
        .item-title {
            width: 35%;
            .font16LightDarkSkyBlue();
        }
        .toggle {
            width: 65%;
            .toggle-value1 {
                width: 50%;
            }
            .toggle-value2 {
                width: 50%;
            }
        }
    }
}