/* TimesheetComplete styling */

.timesheet-complete-header {
    display: flex;
    align-items: center;
    width: 12vw; // complete check-mark height + padding

    .complete-check-mark {
      height: 8vw;
      margin: 4vw 2vw;
    }

    .clickable-area {
      position: absolute;
      height: @headerHeightMobile;
      width: 12vw; // complete check-mark height + padding
    }
}

@import 'TimesheetCompleteWarningModal';
