@import "../../../../less/Fonts";

/* Remove TimesheetCode styling */

.remove-timesheetcode {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding: 6vw 4vw;

  .removable-timesheetcodes {
    .scrollbar-container-removable-timesheetcodes {
      height: 75vh;
    }

    .removable-timesheetcode {
      display: flex;
      align-items: center;
      margin: 4vw 0;

      .clickable-area {
        padding: 2vw 3.5vw;

        img {
          width: 3.5vw;
        }
      }

      .removable-timesheet-code-container {
        margin: 0 4vw;
        display: inline-block;
        .removable-timesheet-code-name {
          .font20RegularGunmetal();
          font-size: 4.2vw;
        }

        .removable-timesheet-code-description {
          .font16RegularBattleShipGrey();
          font-size: 3.3vw;
        }
      }
    }
  }
}
