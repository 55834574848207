
@import "../../../../less/Variables";
@import "../../../../less/Fonts";

//ExportMonthlyDetailList Style

.export-monthly {
    flex: 1 0 auto;
    padding: 6vw 4vw;
    display: flex;
    flex-direction: column;
    align-items: initial;

    .export-monthly-actions {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;

        input[type="submit"], input[type="button"] {
            float: initial;
            margin: 0;
            width: 75vw;
            height: 12.7vw;
            font-size: 4vw;

            &[disabled] {
              background-color: @darkSkyBlue50;
            }
        }

        .button-spacing {
            margin-bottom: 3vw;
        }
    }

    .timesheet-codes-list {
        .no-tscode-select {
            .noSelect();
            .font14RegularGunmetal();
            font-size: 3.4vw;
            line-height: 5.5vw;
            padding-left: 2vw;
            word-wrap: break-word;
            text-align: center;
            text-transform: uppercase;
        }

        .scrollbar-container-timesheet-codes-list {
            height: 55vh;
        }

        .tscode-select {
            display: inline-flex;
            width: 97%;
            box-sizing: content-box;
            padding: 2.5vw 0 2.5vw 2vw;

            .tscode-select-name {
                .font14RegularGunmetal();
                font-size:3.4vw;
                line-height: 5.5vw;
                padding-left: 2vw;
                word-wrap: break-word;
            }
        }
    }
}