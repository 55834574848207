@import "../../../less/Variables.less";
@import "../../../less/Fonts";
@import "../../../less/Mixins";

/* MonthYearPicker styling */

.light-steel {
    color: lighten(@steel, 30%);
}

.month-year-picker {
    .font12MediumSteel();

    .month-year-picker__button {
        cursor: pointer;
        .font14RegularSteel();
        text-transform: uppercase;
        border: none;
        width: 146px;
        text-align: right;
        background-color: transparent;
        outline: none;

        i {
            padding-left: 5px;
        }

        &.month-year-picker__button--readonly {
            cursor: default;

            i {
                display: none;
            }
        }
    }

    .dialog-sheet {
        width: 100%;
        padding: 3px;
        z-index: 9999;
        background-color: white;

        .month-year-picker__selector {
            display: flex;
            line-height: 20px;

            .month-year-picker__year {
                padding: 5px;
                width: 33%;
                text-align: center;
            }

            .month-year-picker__selector__previous {
                cursor: pointer;
                padding: 5px;
                width: 33%;
                text-align: center;

                &.month-year-picker__selector__previous--disabled {
                    .light-steel();
                }
            }

            .month-year-picker__selector__next {
                cursor: pointer;
                padding: 5px;
                width: 33%;
                text-align: center;

                &.month-year-picker__selector__next--disabled {
                    .light-steel();
                }
            }
        }

        .month-year-picker__month__container {
            .month-year-picker__month__row {
                display: flex;
            }

            .month-year-picker__month {
                .font14RegularSteel();
                cursor: pointer;
                width: 33%;
                line-height: 35px;;
                padding-left: 5px;
                text-align: center;
                border-radius: 3px;

                &.month-year-picker__month--selected {
                    .font14MediumWhite();
                    background-color: @darkSkyBlue;
                }
            }
        }
    }
}
