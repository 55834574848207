@import "../../../less/Variables";

/* Modal styling */

.modal-mobile-forefront {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
}

.modal-mobile-dialog {
  width: 100%;
  position: fixed;
  bottom: 0;
}

.modal-mobile-content {
  background-color: @white;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.3);
  padding: @modalPaddingHeight @modalPaddingHeight @modalPaddingHeight 0;
}

.modal-mobile-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.3);
}
