@import "../../../../less/Fonts";
@import "../../../../less/Mixins";

/* Identity styling */

.identity {
  flex: 1;
  display: flex;

  .home {
    display: flex;
    align-items: center;
    padding-right: 10vw;

    .logo {
      position: relative;
      height: 7.3vw;
      left: 4.9vw;
    }

    .title {
      position: relative;
      left: 9.8vw;
      .font19BoldWhite();
      font-size: 4.6vw;
      text-transform: uppercase;
    }
  }
}
