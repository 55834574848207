@import "../../../../less/Variables";
@import "../../../../less/Fonts";

/* TimesheetWeekDay styling */

.timesheet-week-day {
  width: @timesheetDayWidthMobile;
  height: @timesheetDayHeightMobile;
  display: flex;
  align-items: center;
  justify-content: center;
  .font16RegularSteel();
}
