/* ExportMonthly styling */

.export-monthly-header {
    display: flex;
    align-items: center;

    .export-monthly-logo {
        width: 7.4vw;
        margin: 0 2vw;
    }

    .clickable-area {
        position: absolute;
        height: @headerHeightMobile;
        width: 11.4vw; // export-monthly-logo width + margins
    }
}
