@import "../../../less/Fonts";

/* Common styling */

.alert {
  .font12RegularBlack();
  font-size: 3.5vw;
  padding: 2vw 4vw;
  border: 1px solid transparent;
}

.alert-danger {
  background-color: #f2dede;
  border-color: #ebcccc;
  color: #a94442;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 4vw;
  height: 4vw;
  border-radius: 1px;
  background-color: white;
  border: solid 1px @coolGrey;
  vertical-align: middle;
  min-width: 4vw;
  min-height: 4vw;

  &:checked {
    background-color: @darkSkyBlue;
    outline: none;

    &::after {
      position: absolute;
      width: 4vw;
      height: 4vw;
      content: '\f00c';
      font: normal normal normal 14px/1 FontAwesome;
      line-height: 4vw;
      font-size: 2.1vw;
      color: white;
      text-align: center;
      min-width: 4vw;
      min-height: 4vw;
    }
  }
}

input[type="submit"], input[type="button"] {
  .font12MediumWhite();
  font-size: 3.4vw;
  padding: 0 8vw;
  height: 8vw;
  border: none;
  border-radius: 50px;
  background-color: @darkSkyBlue;
  transition: all ease-in-out 0.2s;

  &.inverse {
    color: @steel;
    border: solid 1px @coolGrey;
    background-color: white;
  }

  &:hover, &:active {
    background-color: darken(@darkSkyBlue, 10%);

    &.inverse {
      color: @white;
    }
  }

  &:focus {
    box-shadow: 0 0 0 2px fade(@darkSkyBlue, 50%);
    outline: none;

    &.inverse {
      box-shadow: 0 0 0 2px fade(@coolGrey, 50%);
    }
  }
}

div[role="submit"] {
  .font12MediumSteel();
  font-size: 3.4vw;
  text-align: center;
  padding: 2vw;

  &:hover {
    color: @gunmetal;
  }

  &:disabled {
    color: @white;
    transition: none;
  }
}

input[type="submit"], input[type="button"], div[role="submit"] {
  -webkit-tap-highlight-color: transparent;
  text-transform: uppercase;
  margin-left: 2vw;
  cursor: pointer;
  float: right;
}

@import "TextInput";
@import "Modal";
@import "MobileModal";
@import "MobileClosableModal";
@import "../../../common/ClosableModal";
@import "../../../common/AreYouSureModal";
@import "../../../common/Toggle";
@import "../../../common/CookieConsent";
@import "../../../common/ErrorBoundary";
@import "MonthYearPicker";