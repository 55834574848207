/* ChangeUserDetail styling */

.change-user-detail {
  height: @timesheetHeightMobile;
  display: flex;
  flex-direction: column;

  .timesheet-return {
    .message {
      font-size: 3.6vw;
    }
  }

  .change-user-input {
    width: 100%;
    width: stretch;
    height: 10vw;
    margin-top: 4.5vw;
    margin-bottom: 6vw;
  }

  .change-user {
    flex: 1 0 auto;
    padding: 6vw 4vw;
    display: flex;
    flex-direction: column;
    align-items: initial;

    .change-user-list {
      flex: 1;
      align-items: center;
      justify-content: flex-end;
      margin-top: 3vw;

      .myself {
        margin-bottom: 4vw;
      }

      .scrollbar-container-changeuserdetail {
        height: 55vh;
      }

      .change-user-list-item {
        display: flex;
        align-items: center;
        background-color: @white;
        padding: 1.3vw 0;

        .change-user-avatar {
          margin: 0 @modalPaddingHeight;
          min-width: 8vw;
          min-height: 8vw;
          background-color: @darkSkyBlue50;
          border-radius: percentage(1);
          .change-user-avatarFilling {
            .font12MediumWhite();
            font-size: 3.4vw;
            line-height: 8vw;
            text-align: center;
          }
        }

        .change-user-username {
          .font16LightDarkSkyBlue();
          font-size: 3.8vw;
        }

        &.changeUserSelected {
          background-color: @paleGreyThree;
        }
      }
    }
  }
}
