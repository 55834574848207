@import "../less/Variables";
@import "../less/Fonts";
@import "../less/Mixins";

/* ClosableModal styling */

.closable-modal {
  line-height: 100%;

  .closable-modal-header {
    .closable-modal-close-icon {
      color: @coolGrey;
      float: right;

      .fa-times {
        font-size: 16px;
        cursor: pointer;

        &:hover {
          color: @gunmetal;
        }
      }
    }

    .closable-modal-title {
      .font12MediumSteel();
    }

    .clearFix();
  }

  .closable-modal-cancel {
    margin-top: 18px;
    margin-right: 15px;
    padding: 10px 0 10px 10px;
    float: right;
    .font12MediumSteel();

    &:hover {
      cursor: pointer;
      color: @gunmetal;
    }
  }

  .closable-modal-line {
    height: 1px;
    border-bottom: solid 1px @paleGreyThree;
    margin-left: -20px;
    margin-right: -20px;
  }
}
