@import "../../../../less/Fonts";

/* AddTimesheetCode styling */

.add-timesheet-code {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding: 6vw 4vw;

  .search-timesheetcode-container {
    margin-top: 4vw;
  }

  .searchResultMessage {
    float: left;
    margin: -3.25vw 0 0.25vw 0;
    left: -0.25vw;
    .font12LightSteel();
    font-size: 3vw;
  }

  .addTimesheetCode-selectAll {
    line-height: 3vh;
    margin: 1.5vh 0;
  }

  .addTimesheetCode-selectAll-checkbox {
    float: left;
  }

  .addTimesheetCode-selectAll-checkbox-text {
    float: left;
    .font12RegularDarkSkyBlue();
    font-size: 3.5vw;
    margin-left: 4vw;
  }

  .timesheet-code-tab-container {
    display: flex;

    .timesheet-code-tab {
      .font12MediumGunMetal();
      font-size: 3vw;
      flex: 1;
      line-height: 1.75vh;
      cursor: pointer;

      &.active {
        .font12MediumBlack();
        font-size: 3vw;
      }

      .line {
        margin-top: 2.75vw;
        border-bottom: solid 1px @paleGreyThree;

        &.black {
          border-bottom: solid 1px @gunmetal;
        }
      }
    }
  }

  .timesheet-code-list {
    .scrollbar-container-timesheet-code-list {
      &.has-elements {
        height: 54vh;
      }
    }

    .selectable-timesheet-code {
      display: flex;
      align-items: center;
      margin: 4vw 0;

      .selectable-timesheet-code-container {
        margin: 0 4vw;
        display: inline-block;
        .selectable-timesheet-code-name {
          .font20RegularGunmetal();
          font-size: 4.2vw;
        }
        .selectable-timesheet-code-description {
          .font16RegularBattleShipGrey();
          font-size: 3.3vw;
        }
      }
    }
  }

  .selected-timesheet-code {
    display: flex;
    align-items: center;
    margin: 4vw 0;

    .selected-timesheet-code-container {
      margin: 0 4vw;
      display: inline-block;
      .selected-timesheet-code-name {
        .font20RegularGunmetal();
        font-size: 4.2vw;
      }
    }
  }

  .add-selected {
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    input[type="submit"] {
      width: 80vw;
      height: 12.7vw;
      font-size: 4vw;

      &[disabled] {
        background-color: @darkSkyBlue50;
      }
    }
  }

  .no-most-recent-tscodes {
    .noSelect();
    float: left;
    margin: 3.25vw 0 0.25vw 0;
    left: -0.25vw;
    .font12LightSteel();
    font-size: 3vw;
  }
}
