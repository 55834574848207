@import "../../../../less/Variables";
@import "../../../../less/Fonts";

/* TimesheetReturn styling */

.timesheet-return {
  flex: 0 0 @headerHeightMobile;
  box-shadow: 0 1px 0 0 @silver;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .return {
    position: absolute;
    bottom: 0.5vw;
    left: 0;
    line-height: ~"calc(@{headerHeightMobile} - 1vw)";
    width: 11.2vw;
    text-align: center;
    font-size: 9vw;
    color: @steel;
  }

  .message {
    position: relative;
    top: 0.2vw;
    .font18MediumSteel();
    font-size: 4vw;
    text-transform: uppercase;
  }
}
