@import "../../../../less/Variables";
@import "../../../../less/Fonts";

/* TimesheetFooterTotal styling */

.timesheet-footer {
  .total {
    .total-separator {
      height: 1px;
      background-color: @silver;
      margin: 2vw 0;
    }

    .total-hours-day {
      display: flex;
      align-items: center;
      height: 11.2vw;

      .total-label {
        .font20MediumSteel();
        font-size: 4.5vw;
      }

      .total-hours {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        span {
          .font20MediumGunmetal();
          font-size: 4.5vw;
          margin-left: 1.5vw;
        }

        label {
          .font20RegularSteel();
          font-size: 4.5vw;
          margin-left: 1vw;
        }

        .expand-hours {
          color: transparent;
          font-size: 7vw;
          margin-left: 3.5vw;
        }
      }
    }
  }

  .no-registered-hours {
    .font20RegularGunmetal();
    font-size: 4.5vw;
    line-height: 12.5vw;
    text-align: center;
  }
}
