@import "../../../../less/Variables";

/* TimesheetFooter styling */

.timesheet-footer {
  flex: 1 0 auto;
  padding: 0 3.5vw;
  background-color: @paleGrey;
  box-shadow: inset 0 1px 0 0 @silver;
  display: flex;
  flex-direction: column;

  .active {
    flex: 1;
    display: flex;
    flex-direction: column;

    .add-hours {
      flex: 1;
      padding: 7vw 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;

      input[type="submit"] {
        float: initial;
        margin: 0;
        width: 75vw;
        height: 12.7vw;
        font-size: 4vw;

        &[disabled=''] {
          background-color: @darkSkyBlue50;
        }
      }
    }
  }

  .timesheet-footer__footer-info {
    display: flex;
    height: 20px;
  }
}

@import "TimesheetFooterDay";
@import "TimesheetFooterCode";
@import "TimesheetFooterTotal";
@import "Version";
@import "Disclaimer";
@import "MaintenanceModeDisclaimer";