@import "../../../../less/Variables";
@import "../../../../less/Fonts";

//ExportMonthlyDetailExtraInfo Style

.export-monthly-extra-info {
    flex: 1 0 auto;
    padding: 6vw 4vw;
    display: flex;
    flex-direction: column;
    align-items: initial;

    .input-fields {
        padding: 4.5vw;

        .form-group {
            .toggle {
                height: 6vw;
                line-height: 6vw;
                font-size: 2.5vw;
            }
        }

        .company-group {
            label {
                &:after{
                    content: '*';
                    margin-left: 5px;
                }
            }
            input {
                display: block;
                resize: none;
                width: 100%;
                padding: 2vw;
                background-color: @white;
                border: solid 1px @silverTwo;
                .font14RegularGray();
                font-size: 4vw;
                transition: border-color ease-in-out 0.15s;

                &:focus {
                  border-color: #5cb3fd;
                  outline: none;
                }

                &::placeholder {
                  color: #636c72;
                }

                &:disabled, :read-only {
                  background-color: @disabledGrey;
                }
            }

            .company-data {
                padding: 2vw;
                .font14RegularGray();
                font-size: 4vw;
            }

            .react-autosuggest__suggestions-list {
                list-style-type: none;
            }
        }
    }

    .export-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;

        input[type="submit"], input[type="button"] {
            float: initial;
            margin: 0;
            width: 75vw;
            height: 12.7vw;
            font-size: 4vw;
        }

        .disabled {
            background-color: @darkSkyBlue50;
        }
    }

    .export-monthly-extra-info__required_message {
        .font12RegularSteel();
        margin-top: -15px;
        font-size: 3vw;
    }
}