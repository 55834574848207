@import "../../../../less/Variables";
@import "../../../../less/Fonts";

/* ProfileModal styling */

.menu-item {
  .font14MediumDarkSkyBlue();
  font-size: @modalFontSize;
  text-transform: uppercase;
  padding: @modalPaddingHeight;
}
