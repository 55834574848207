@import "../../../less/Variables";
@import "../../../less/Fonts";

/* TextInput styling */

.form-group {
  margin-bottom: 5.5vw;
  overflow: hidden;

  label {
    display: inline-block;
    margin-bottom: 2.5vw;
    .font14RegularSteel();
    font-size: 4vw;
    transition: color ease-in-out 0.15s;

    &.has-extra {
      color: @gunmetal;

      span {
        font-size: 3.5vw;
        color: @steel;
        transition: color ease-in-out 0.15s;
      }
    }

    &.required {
      &:after {
        content: '*';
        margin-left: 5px;
      }
    }
  }

  .form-control {
    display: block;
    resize: none;
    width: 100%;
    padding: 2vw;
    background-color: @white;
    border: solid 1px @silverTwo;
    .font14RegularGray();
    font-size: 4vw;
    transition: border-color ease-in-out 0.15s;

    &:focus {
      border-color: #5cb3fd;
      outline: none;
    }

    &::placeholder {
      color: #636c72;
    }

    &:disabled, &:read-only {
      background-color: @disabledGrey;
    }
  }

  &.has-error {
    label {
      color: @pigPink;

      span {
        color: @pigPink;
      }
    }

    .form-control {
      border-color: @pigPink;
    }
  }
}
