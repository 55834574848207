@import "../../../../less/Variables";
@import "../../../../less/Fonts";

/* TimesheetFooterCode styling */

.timesheet-footer {
  .timesheet-code {
    display: flex;
    align-items: center;
    height: 11.2vw;

    .timesheet-code-name {
      .font20RegularGunmetal();
      font-size: 4.5vw;
    }

    .timesheet-code-total {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .comment-indicator {
        color: #f9c681;
        font-size: 2vw;
      }

      span {
        .font20MediumGunmetal();
        font-size: 4.5vw;
        margin-left: 1.5vw;
      }

      label {
        .font20RegularSteel();
        font-size: 4.5vw;
        margin-left: 1vw;
      }

      &.has-extra-hours {
        span, label {
          color: @darkSkyBlue;
        }
      }

      .expand-hours {
        color: @steel;
        font-size: 7vw;
        margin-left: 3.5vw;
      }
    }
  }

  .timesheetcode-inactive {
    text-decoration: line-through;
  }
}
