@import "../../../../less/Variables";
@import "../../../../less/Fonts";

/* TimesheetCompleteWarningModal styling */

.timesheet-complete-detail {
  .closable-mobile-modal {
    .timesheet-complete-warning-month-year {
      .font14RegularGunmetal();
      font-size: 3.9vw;
      padding: 0 @modalPaddingHeight @modalPaddingHeight;
    }

    .timesheet-complete-warning-attention {
      .font14MediumWarmPink();
      font-size: 3.9vw;
      padding: @modalPaddingHeight;
    }

    .timesheet-complete-warning-message {
      .font14RegularWarmPink();
      font-size: 3.9vw;
      padding: @modalPaddingHeight;
    }

    .timesheet-complete-warning-button-container {
      display: flex;
      flex-direction: row-reverse;

      .timesheet-complete-warning-complete {
        margin: auto 0;
        float: initial;
      }

      .timesheet-complete-warning-cancel {
        margin: auto 0;
        padding: @modalPaddingHeight;
        .font12MediumSteel();
        font-size: 3.35vw;
      }
    }
  }
}
