@import "../../../less/Variables";

/* Header styling */

.header {
  height: @headerHeightMobile;
  display: flex;
  background-color: @darkSkyBlue;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}

@import "identity/Identity";
@import "profile/Profile";
@import "exportMonthly/ExportMonthly";
@import "timesheetComplete/TimesheetComplete";
@import "profile/ChangeUserDetail";
