@import "../../../../less/Fonts";

/* Profile styling */

.profileContainer {
  display: flex;
  align-items: center;

  .profile {
    margin-right: 3vw;
    margin-left: 2vw;

    .profile-avatar {
      min-width: 10vw;
      min-height: 10vw;
      line-height: 10vw;
      background-color: @white50;
      border-radius: percentage(1);
      .font14MediumWhite();
      font-size: 3.5vw;
      text-align: center;
    }
  }

  .clickable-area {
    position: absolute;
    height: @headerHeightMobile;
    width: 15vw; // profile-avatar width + margins
  }
}

@import "ProfileModal";
