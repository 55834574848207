@import "../../../../less/Variables";

/* Timesheet styling */

.timesheet, .timesheet-detail, .export-monthly-detail, .timesheet-complete-detail {
  height: @timesheetHeightMobile;
  display: flex;
  flex-direction: column;
}

@import "TimesheetNavigation";
@import "TimesheetWeek";
@import "TimesheetDays";
@import "TimesheetFooter";
@import "../settings/SettingsDetail";
@import "TimesheetReturn";
@import "TimesheetEntry";
@import "ExportMonthlyDetail";
@import "TimesheetCompleteDetail";
@import "AddTimesheetCode";
@import "RemoveTimesheetCode";
