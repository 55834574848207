@import "../less/Fonts";

/* ErrorBoundary styling */

.wrong {
  padding: 0 20px;
  .font18MediumBlack();

  h1 {
    .font36MediumBlack();
  }

  .wrong-error-message {
    padding-top: 20px;
    .font12LightGrey();
  }
}