@import url("https://fonts.googleapis.com/css?family=Rubik:300,400,500,900.css");
@import url("https://use.fontawesome.com/releases/v5.0.13/css/all.css");
@import "../../less/Mixins";

/* App styling */

body {
  margin: 0;
  .noSelect();
}

* {
  box-sizing: border-box;
}

.clear-fix {
  .clearFix();
}

input::-moz-focus-inner, button::-moz-focus-inner {
  border: 0
}

@import "header/Header";
@import "content/Content";
@import "common/Common";
