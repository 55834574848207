@import "../../../../less/Variables";
@import "../../../../less/Fonts";

/* TimesheetDay styling */

.timesheet-day {
  width: @timesheetDayWidthMobile;
  height: @timesheetDayHeightMobile;
  display: flex;
  align-items: center;

  .day {
    margin: 0 auto;
    width: 46px;
    height: 46px;
    line-height: 46px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 50%;
    .font16MediumSteel();

    &.active {
      background-color: @paleGreyThree;
      color: @darkSkyBlue;

      .entry-indicator {
        color: @darkSkyBlue;
      }
    }

    &.today {
      background-color: @darkSkyBlue;
      color: @white;

      .entry-indicator {
        color: @white;
      }
    }

    .entry-indicator {
      pointer-events: none;
      color: #dee2e6;
      font-size: 7px;
      position: relative;
      top: -12px;
    }
  }
}
