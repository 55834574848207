@import "../../../../less/Variables";
@import "../../../../less/Fonts";
@import "../../../../less/Mixins";

/* LoginModal styling */

.login-modal-options {
  align-items: center;
  display: flex;
  .forgot-password {
    flex: 1;
     
    .forgot-password-text {
      .defaultFontRegular(14px, @darkSkyBlue);
      width: fit-content;
      padding: 5px;

      &:hover {
        color: darken(@darkSkyBlue, 15%);
        cursor: pointer;
      }
    }
      
  }  
}

.not-you {
  display: unset;
  .defaultFontRegular(12px, darken(@darkSkyBlue, 5%));

  &:hover {
    color: darken(@darkSkyBlue, 20%);
    cursor: pointer;
  }
}

.login-selection {
    width: 100%;
    height: 14vh;
  
    .select-block {
      display: grid;
      grid-template-columns: 30% 30% 40%;
      grid-template-rows: 20% 10% 45% 25%;
      height: 100%;
      margin: 5px;
      padding-top: 5px;
      z-index: 1;
  
      .form-group {
          grid-row: 3;
          grid-column-start: 2;
          grid-column-end: 4;
          overflow: visible;
          margin:0;
          
          .form-control {
            .font14RegularSteel();
          }
      }

      .logo {
        grid-row: 1;
        grid-column: 1;
        color: @darkSkyBlue;
        font-size: 22vw;
      }
  
      .title {
        grid-row: 1;
        grid-column: 2;
        .font20MediumDarkSkyBlue();
      }
      
      .login-selection-button {
        grid-row: 4;
        grid-column: 3;

        padding: 0;
        max-height: 7vw;
      }
    }
  }
