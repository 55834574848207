@import "../../../less/Variables";
@import "../../../less/Fonts";
@import "../../../less/Mixins";

/* MobileClosableModal styling */

.closable-mobile-modal {
  line-height: 100%;

  .closable-mobile-modal-header {
    .closable-mobile-modal-close-icon {
      color: @coolGrey;
      float: right;

      .fa-times {
        font-size: 5vw;
        cursor: pointer;
      }
    }

    .closable-mobile-modal-title {
      .font18MediumSteel();
      font-size: @modalFontSize;
      text-transform: uppercase;
      padding: @modalPaddingHeight;
    }

    .clearFix();
  }
}
