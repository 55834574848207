@import "../../../less/Variables";
@import "../../../less/Fonts";

@import '../../../../node_modules/react-toastify/dist/ReactToastify.css';

/* Content styling */

.modal-dialog-override {
  height: 50vh;
}

.inMaintenance {
  .noSelect();
  text-transform: uppercase;
  .font14MediumWarmPink();
  font-size: 4.5vw;
  text-align: center;
}

.toastify {
  .noSelect();
  width: 100vw;
  padding: 0;
  left: 0;
  top: 0;

  &.info {
    background-color: #0A80FC;
  }

  &.error {
    background-color: indianred;
  }

  .toastify-body {
    height: @headerHeightMobile + 2vw;
    .font14RegularWhite();
    font-size: 4vw;
    text-align: center;
    margin: 0 5px;
    padding-top: 20px;
    padding-left: 20px;

    &.info {
      background-color: #0A80FC;
    }

    &.error {
      background-color: indianRed;
    }
  }
}

.back-close-icon {
  color: @coolGrey;
  float: right;

  .fa-times {
    font-size: 20px;
    cursor: pointer;

    &:hover {
      color: @gunmetal;
    }
  }
}

.account-selection {
  width: 100%;
  height: 14vh;
  display: flex;
  text-align: center;

  .select-block {
    display: block;
    height: 100%;
    width: 50%;
    margin: 5px;
    padding-top: 5px;
    z-index: 1;

    .logo {
      color: @darkSkyBlue;
      font-size: 36px;
    }

    .title {
      .font16MediumDarkSkyBlue();
    }

    .info {
      .font10RegularSteel();
    }
  }
}

@import "timesheet/Timesheet";
@import "login/LoginModal";
